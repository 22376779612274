import { useState, useEffect, createElement, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import '../App.css';

import React from 'react';
import Cell from './Cell.tsx';
import output from '../connections-output.jpg'


const c1 = new Set(["fellowship", "king", "tower", "desolation"]);
const c2 = new Set(["ring", "torch", "flag", "medal"]);
const c3 = new Set(["knight", "queen", "rook", "pawn"]);
const c4 = new Set(["imagine dragons", "prince", "jewel", "ice cube"]);
const answers = [c1, c2, c3, c4];

const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value.toLowerCase()));

const WordGroup = () => {
	const [gameState, setGameState] = useState("NEUTRAL");
	const [buttonVisibility, setButtonVisiblity] = useState("hidden");
	const [imageVisibility, setImageVisiblity] = useState("hidden");
	const [words, setWords] = useState([
		useRef(), useRef(), useRef(), useRef(),
		useRef(), useRef(), useRef(), useRef(),
		useRef(), useRef(), useRef(), useRef(),
		useRef(), useRef(), useRef(), useRef(),
	]);
	// popup
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);

	const NUM_ROWS = 4;
	const ROW_LENGTH = 4;

	function setCellStyle() {
		for (var i = 0; i < words.length; i++) {
			words[i].current.setCheck(gameState);
		}
	}

	React.useEffect(() => {
		if (gameState == "WIN") {
			return;
		}
		setCellStyle();
	}, [gameState]);

	function checkAnswer() {
		console.log("checkAnswer");
		let rowsCorrect = new Set();

		for (let i = 0; i < NUM_ROWS; i++) {
			let rowWords = new Set();
			for (let j = 0; j < ROW_LENGTH; j++) {
				const word = words[i * ROW_LENGTH + j].current.getWord().trim();
				rowWords.add(word);
			}

			for (let j = 0; j < answers.length; j++) {
				const answerRow = answers[j]
				if (areSetsEqual(rowWords, answerRow)) {
					rowsCorrect.add(j);
				}
			}
		}

		if (rowsCorrect.size === NUM_ROWS) {
			console.log("you win!");
			// set color
			setGameState("WIN");
			// setImageVisiblity("visibile");
			setButtonVisiblity("hidden");
		}

		else {
			for (var i = 0; i < words.length; i++) {
				setGameState("LOSE");
				setOpen(true);
			}
		}
	}

	function cellChanged() {
		if (gameState === "LOSE") {
			setGameState("NEUTRAL");
		}

		let allSquaresGuessed = true;
		for (let i = 0; i < NUM_ROWS; i++) {
			for (let j = 0; j < ROW_LENGTH; j++) {
				const word = words[i * ROW_LENGTH + j].current.getWord().trim();
				if (!word) {
					allSquaresGuessed = false;
				}
			}
		}


		setButtonVisiblity(allSquaresGuessed ? "visible" : "hidden");
	}


	if (gameState == "WIN") {
		console.log("win");
		return (
			<div id="solution">
				<img src={output} />
			</div >

		);
	} else {
	return (

		<React.Fragment>
			<div className="row">
				<Cell cellChanged={cellChanged} ref={words[0]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[1]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[2]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[3]} check=""></Cell>
			</div>
			<div className="row">
				<Cell cellChanged={cellChanged} ref={words[4]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[5]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[6]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[7]} check=""></Cell>
			</div>
			<div className="row">
				<Cell cellChanged={cellChanged} ref={words[8]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[9]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[10]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[11]} check=""></Cell>
			</div>
			<div className="row">
				<Cell cellChanged={cellChanged} ref={words[12]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[13]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[14]} check=""></Cell>
				<Cell cellChanged={cellChanged} ref={words[15]} check=""></Cell>
			</div>
			<button className={buttonVisibility} type="button" onClick={checkAnswer}>Submit</button>
			<Popup open={open} position="center right" className="popup">
				<div className="modal">
					<div className="close">
						<button className="close" onClick={closeModal}>
							X
						</button>
					</div>
					<div>
						<p>Keep trying …you have at least 2 categories incorrect! </p></div></div></Popup>
		</React.Fragment>
	);
	}
};

export default WordGroup;
