import React from 'react';
import '../App.css';
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';

const Cell = forwardRef((props, _ref) => {
	const [word, setWord] = useState("");
	const [check, setCheck] = useState("");

	const handleChange = (event) => {
		setWord(event.target.value);
	}

	React.useEffect(() => {
		props.cellChanged();
	}, [word]);

	useImperativeHandle(_ref, () => ({
		getWord: () => {
			return word;
		},
		setCheck: (value) => {
			console.log("value");
			setCheck(value)
		},
	}));

	return (

		<div className="cell" style={{ width: "150px", height: "150px" }}>
			<input className={check} onChange={handleChange} style={{ width: "150px", height: "150px" }}></input>
		</div>
		);
});
export default Cell;
