import React, { useState, useEffect, createElement } from 'react';
import { Container } from "react-bootstrap";
import ReactDOM from 'react-dom';
import WordGroup from './WordGroup.tsx';
import { generateWord, checkGuess } from '../utils/WordUtils.tsx';


type BoardProps = {
}

type BoardState = {
	curr: number;
	wordGroup: any;
	currWord: number;
}

export default class Board extends React.Component<BoardProps, BoardState> {
	constructor(props) {
		super(props);

		this.state = {
			curr: 0,
			wordGroup: createElement(WordGroup),
			currWord: 0,
		}
	}

	render() {
		return (
			<Container>
				<div className="board">
					{this.state.wordGroup}
				</div>
			</Container>
		);
	}

};
