import './App.css';
import Board from './components/Board.tsx';
import { makeStyles } from '@mui/styles';


function App() {

  const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 40,
    justifyContent: 'center',
    backgroundColor: 'red',
    fontSize: '40px'
  }
}));

  return (
    <div className="App">
      <h1>Connections</h1>
      <p>Fill out all rows before submitting!</p>
      <Board />
    </div>
  );
}

export default App;
